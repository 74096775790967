<template>
	<b-card class="p-2" no-body>
		<b-row class="d-flex flex-column-reverse flex-md-row">
			<b-col class="d-flex justify-content-between align-items-end" cols="12" md="8" xl="9">
				<b-nav pills class="m-0 d-flex flex-column flex-md-row container-tabs">
					<b-nav-item
						style="min-width: 14rem; background-color: #cecece !important; border-radius: 8px 8px 0 0"
						class="position-relative h-100"
						active-class="active"
						:to="{ name: 'tournaments-dashboard' }"
					>
						<span class="text-white font-weight-bolder">Dashboard</span>
					</b-nav-item>
					<b-nav-item
						style="min-width: 14rem; background-color: #cecece !important; border-radius: 8px 8px 0 0"
						class="position-relative h-100"
						active-class="active"
						:to="{ name: 'tournaments-canvan' }"
					>
						<span class="text-white font-weight-bolder">Torneos</span>
					</b-nav-item>
					<b-nav-item
						style="min-width: 14rem; background-color: #cecece !important; border-radius: 8px 8px 0 0"
						class="position-relative h-100"
						active-class="active"
						:to="{ name: 'tournaments-events' }"
					>
						<span class="text-white font-weight-bolder">Fechas</span>
						<b-badge
							class="position-absolute"
							style="top: 0.5rem; right: 2rem"
							variant="danger"
							v-if="counterEvents"
						>
							{{ counterEvents }}
						</b-badge>
					</b-nav-item>
				</b-nav>
			</b-col>
			<b-col
				class="d-flex mb-1 flex-column flex-md-row justify-content-md-end align-items-center"
				cols="12"
				md="4"
				xl="3"
			>
 				<div class="d-flex align-items-center w-100 justify-content-end">
					<b-form-select
						text-field="season"
						value-field="id"
						:options="seasonsT"
						v-model="season"
						style="max-width: 15rem; height: 42px !important; min-width: 8rem"
					/>
					<b-btn
						v-b-tooltip.hover
						title="Calendario de torneos"
						class="ml-1 position-relative"
						variant="primary"
						:id="`bookmark-calendar-tournament`"
						:to="{ name: 'tournaments-calendar' }"
					>
 						<feather-icon icon="CalendarIcon" size="22" />
						<b-badge
							class="position-absolute"
							style="top: -0.5rem; right: -0.5rem"
							variant="danger"
							v-if="counterCalendar"
						>
							{{ counterCalendar }}
						</b-badge>
					</b-btn>
				</div>
 				<b-button
					v-if="$route.name == 'tournaments-canvan'"
					@click="modalTorneo = true"
					variant="primary"
					class="d-flex align-items-center justify-content-center ml-0 ml-md-1 text-nowrap mt-1 mt-md-0 container-add-date"
				>
					<feather-icon class="text-white mr-50" icon="PlusIcon" size="18" />
					Agregar torneo
				</b-button>
				<b-button
					class="d-flex align-items-center justify-content-center ml-0 ml-md-1 text-nowrap mt-1 mt-md-0 container-add-date"
					variant="primary"
					@click="triggerAddEvent++"
					v-if="$route.name == 'tournaments-events'"
				>
					<feather-icon class="mr-05" icon="PlusIcon" size="18" />
					Agregar fecha(s)
				</b-button>
			</b-col>
		</b-row>

		<b-overlay :show="isLoading">
			<router-view
				v-if="curSeasonT"
				:season="curSeasonT"
				:triggerAddEvent="triggerAddEvent"
				@refresh="getEventsCounter"
			/>
			<div style="height: 60vh" v-else />
		</b-overlay>

		<AddTournament v-if="modalTorneo" :show="modalTorneo" @closing="modalTorneo = false" />
	</b-card>
</template>

<script>
import tournamentSeasonService from "@/services/tournament/season.service"
import tournamentService from "@/services/tournament/default.service"
import AddTournament from "./components/AddTournament.vue"
import router from "@/router"
import useCalendar from "@/views/amg/tournaments/v2/components/calendar/useCalendar.js"

export default {
	components: { AddTournament },
	data: () => ({
		isLoading: false,
		seasonsT: [],
		season: null,
		modalTorneo: false,
		triggerAddEvent: 0,
		counterEvents: null,
		counterCalendar: 0,
	}),
	computed: {
		curSeasonT() {
			return this.seasonsT.find((season) => season.id == this.season)
		},
		routeName() {
			return router.currentRoute.name
		},
	},
	methods: {
		async getSeasons() {
			this.isLoading = true
			const { data } = await tournamentSeasonService.getSeasons()
			this.seasonsT = data
			let current = data.find((it) => it.active_season == 1)
			if (current) this.season = current.id
			this.getEventsCounter()
			this.getCounterCalendar()
			this.isLoading = false
		},

		async getEventsCounter() {
			const { data } = await tournamentService.getCounters({
				start_temporary_date: this.curSeasonT.start,
				end_temporary_date: this.curSeasonT.end,
			})
			// let actual = data.find((c) => c.id == 1).counter
			let futures = data.find((c) => c.id == 5).counter
			this.counterEvents = futures
		},
		async getCounterCalendar() {
			const {
				data: { counter },
			} = await tournamentService.counterCalendar()

			this.counterCalendar = counter
		},
	},
	created() {
		this.getSeasons()
	},
	watch: {
		counterTrigger(val) {
			this.getCounterCalendar()
		},
	},
	setup() {
		const { counterTrigger } = useCalendar()
		return { counterTrigger }
	},
}
</script>

<style scoped lang="scss">
.container-tabs {
	width: 100%;
}

.container-add-date {
	width: 100%;
}
</style>
